import { FormatCurrencyFun } from "../../../contexts/cart-context";
import {
  AccountInformation,
  CartItem,
  Customer,
  DELIVERY_METHODS,
  OrderInterface,
  StoreInterface,
} from "../../interfaces";
import { toAppUrl, toCurrency } from "./functions";
import { resolvePhone, getFullProductName, stripAccount, getActualPrice } from "./utils";

const formatCustomer = (
  customer: Customer,
  delivery_method: DELIVERY_METHODS,
  delivery_info: OrderInterface["delivery_info"]
) => {
  let customerString,
    deliveryInfoString = "";

  customerString = `🙍🏽‍♂️ Customer: ${customer.name}`;

  if (delivery_method === DELIVERY_METHODS.PICKUP) {
    return `${customerString}\n\n*Delivery Method:* Pickup`;
  }

  deliveryInfoString = `Name: ${delivery_info?.name}\nPhone: ${resolvePhone(delivery_info?.phone)}\nAddress: ${
    delivery_info?.delivery_address
  }`;

  return `${customerString}\n\n*DELIVERY INFO:*\n${deliveryInfoString}`;
};

const orderCTAText = (order: OrderInterface) => {
  if (!order.id || !(order?.store as StoreInterface)?.payments_enabled) return "🛍️ Check out cart here:";
  if (order.invoice) return "🛍️ Click here update to order or make payments:";
  return "🛍️ Click here to view or update order:";
};

const getPaymentText = (order: OrderInterface, account?: AccountInformation, receiptLink?: string) => {
  const orderCanCollectPayments = (order?.store as StoreInterface)?.payments_enabled && Boolean(order?.invoice);
  const storeHasAccount = Boolean(account);
  let paymentText = "";

  if (!orderCanCollectPayments) return "";

  if (receiptLink) {
    paymentText = `-----------------------------------\n*PAYMENT RECEIPT*\n\n${receiptLink}\n`;
  } else if (account) {
    paymentText = `-----------------------------------\n*FOR PAYMENTS*\n\n${
      storeHasAccount ? "*OPTION 1:* " : ""
    }Use the link attached to this order 🔗\nPayments made via the link are automatically confirmed\n${
      storeHasAccount
        ? `\n*OPTION 2*: Transfer to this bank account 🏦\nAcc Num: ${account?.account_number}\nBank: ${
            account?.bank_name
          }\nAcc Name: ${stripAccount(account?.account_name)}\n`
        : ""
    }`;
  }

  return paymentText;
};

const getOrderNoteText = (order: OrderInterface) => {
  if (!order?.order_notes) return "";

  return `📝 *ORDER NOTES:*\n${order.order_notes}\n\n-----------------------------------\n\n`;
};

const getOrderItemsText = (order?: OrderInterface) => {
  const items = order.items;

  const getItemFullPrice = (item: CartItem) => {
    return toCurrency(item.quantity * getActualPrice(item?.variant ? item?.variant : item?.snapshot), order?.currency);
  };

  return items
    .map((c) =>
      c.is_deleted || c.is_unavailable
        ? ""
        : `📦 ${getFullProductName(c)} - ${c.quantity}x - ${getItemFullPrice(c).replace(".00", "")}\n`
    )
    .join("");
};

export const getOrderMessage = (
  order: OrderInterface,
  prefix: string,
  account?: AccountInformation,
  receiptLink?: string,
  stripAsteriks?: boolean
) => {
  let feesText = "";
  const orderId = order?.id;
  const customer = { ...order?.customer };
  const link = toAppUrl(`orders/${orderId}`);
  const totalPrice = toCurrency(order?.total_amount, order?.currency); //set total amount to amount from response

  let extraDetails = "";

  if (order.extra_details && Object.keys(order.extra_details)?.length > 0) {
    extraDetails = "\n\n*EXTRA DETAILS:*\n\n" + Object.keys(order.extra_details).map((key) => `*${key?.replace(/_/g," ").trim()}*: ${order.extra_details[key]}`).join("\n");
  }

  if (order.fees) {
    const feeTypeEmoji = {
      DISCOUNT: "🎁",
      DELIVERY: "🚚 ",
      OTHERS: "💵",
      COUPON: "🏷️",
    };

    feesText = order.fees.reduce((cumm, fee) => {
      return `${cumm}\n${feeTypeEmoji[fee.type]} ${fee.label}: ${toCurrency(fee.amount, order?.currency)}`;
    }, "");
  }

  customer.phone = resolvePhone(customer.phone);

  const message = `${prefix}\n\n${getOrderItemsText(order)}\n${formatCustomer(
    customer,
    order?.delivery_method,
    order?.delivery_info
  )}\n${feesText}\n💰 Total Price: *${totalPrice}*\n\n${getOrderNoteText(order)}${orderCTAText(
    order
  )}\n${link}/\n\n${getPaymentText(order, account, receiptLink)}${
    orderId ? `-----------------------------------\n\nOrder ID: *${orderId}*` : ""
  }${extraDetails}`;

  return stripAsteriks ? message.replace(/\*/g, "") : message;
};

const getWhatsappLink = (
  order: OrderInterface,
  prefix: string,
  phone: string,
  account?: AccountInformation,
  receiptLink?: string
) => {
  return encodeURI(
    `https://api.whatsapp.com/send/?phone=${resolvePhone(phone)}&text=` +
      getOrderMessage(order, prefix, account, receiptLink)
  );
};

export const getInstagramDMLink = (username: string) => `https://ig.me/m/${username}`;

export default getWhatsappLink;
